<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 년도 -->
          <c-datepicker
            type="year"
            default="today"
            label="LBLYEAR"
            name="year"
            v-model="searchParam.year"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            stepperGrpCd="HEA_MUSCLE_SURVEY_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="heaMuscleSurveyStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.heaMuscleSurveyStepCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      title="근골격계질환 증상조사 설문 현황"
      :columns="gridColumns"
      :data="grid.data"
      :merge="gridMerge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazardInvestigation',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
        // heaMuscleSurveyStepCd: null,
        heaSurveyCategoryCd: '',
      },
      grid: {
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridMerge() {
      let merge = [];
      if (this.$route.meta.params.heaSurveyCategoryCd === 'MSC0000005') {
        merge = [
          { index: 0, colName: 'heaInvestigationPlanId' },
          { index: 1, colName: 'heaInvestigationPlanId' },
          { index: 2, colName: 'heaInvestigationPlanId' },
        ]
      }
      return merge;
    },
    gridColumns() {
      let cols = [];
      if (this.$route.meta.params.heaSurveyCategoryCd !== 'MSC0000005') {
        cols = [
          {
            name: 'upDeptName',
            field: 'upDeptName',
            label: '상위부서',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: false,
            style: 'width:90px',
            type: 'link',
          },
          {
            name: 'age',
            field: 'age',
            label: '나이(만)',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'jobRelation',
            field: 'jobRelation',
            label: '작업관련성(3개 문항)',
            child: [
              {
                name: 'leisureExerciseIntensity',
                field: 'leisureExerciseIntensity',
                label: '여가운동<br/>강도(4점)',
                align: 'center',
                sortable: false,
                style: 'width:90px',
              },
              {
                name: 'houseworkTime',
                field: 'houseworkTime',
                label: '가사노동<br/>시간(5점)',
                align: 'center',
                sortable: false,
                style: 'width:90px',
              },
              {
                name: 'workIntensity',
                field: 'workIntensity',
                label: '작업 강도<br/>(5점)',
                align: 'center',
                sortable: false,
                style: 'width:90px',
              },

            ]
          },
          {
            name: 'surgeryHistory',
            field: 'surgeryHistory',
            label: '수술이력',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'part',
            field: 'part',
            label: '부위',
            align: 'center',
            sortable: false,
            style: 'width:350px',
          },
          {
            name: 'symptom',
            field: 'symptom',
            label: '근골격계 증상(4개 문항)',
            child: [
              {
                name: 'painScore',
                field: 'painScore',
                label: '통증점수',
                align: 'center',
                sortable: false,
                style: 'width:90px',
              },
              {
                name: 'painOccurs',
                field: 'painOccurs',
                label: '통증발생<br/>시기(3)',
                align: 'center',
                sortable: false,
                style: 'width:90px',
              },
              {
                name: 'painDuration',
                field: 'painDuration',
                label: '통증 지속시간<br/>(6점)',
                align: 'center',
                sortable: false,
                style: 'width:100px',
              },
              {
                name: 'painFrequency',
                field: 'painFrequency',
                label: '통증 발생빈도<br/>(6점)',
                align: 'center',
                sortable: false,
                style: 'width:100px',
              },
              {
                name: 'decreasedWorkEfficiency',
                field: 'decreasedWorkEfficiency',
                label: '작업능률 저하<br/>(5점)',
                align: 'center',
                sortable: false,
                style: 'width:100px',
              },
            ]
          },
          {
            name: 'treatmentStatus',
            field: 'treatmentStatus',
            label: '치료여부',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'relatedWork',
            field: 'relatedWork',
            label: '작업연관성여부',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'harmfulFactors',
            field: 'harmfulFactors',
            label: '유해요인',
            align: 'center',
            sortable: false,
            style: 'width:250px',
          },
          {
            name: 'musclePreventionOpinion',
            field: 'musclePreventionOpinion',
            label: '근골예방 의견',
            align: 'left',
            sortable: false,
            style: 'width:200px',
          },
          {
            name: 'ache',
            field: 'ache',
            label: '통증',
            child: [
              {
                name: 'acheLeisure',
                field: 'acheLeisure',
                label: '여가',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'acheLyrics',
                field: 'acheLyrics',
                label: '가사',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'acheWork',
                field: 'acheWork',
                label: '작업',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
            ]
          },
          {
            name: 'durationPain',
            field: 'durationPain',
            label: '통증지속 기간',
            child: [
              {
                name: 'durationPainLeisure',
                field: 'durationPainLeisure',
                label: '여가',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'durationPainLyrics',
                field: 'durationPainLyrics',
                label: '가사',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'durationPainWork',
                field: 'durationPainWork',
                label: '작업',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
            ]
          },
          {
            name: 'frequencyPain',
            field: 'frequencyPain',
            label: '통증발생 빈도',
            child: [
              {
                name: 'frequencyPainLeisure',
                field: 'frequencyPainLeisure',
                label: '여가',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'frequencyPainLyrics',
                field: 'frequencyPainLyrics',
                label: '가사',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'frequencyPainWork',
                field: 'frequencyPainWork',
                label: '작업',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
            ]
          },
          {
            name: 'decreasedWork',
            field: 'decreasedWork',
            label: '작업능률 저하',
            child: [
              {
                name: 'decreasedWorkLeisure',
                field: 'decreasedWorkLeisure',
                label: '여가',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'decreasedWorkLyrics',
                field: 'decreasedWorkLyrics',
                label: '가사',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'decreasedWorkWork',
                field: 'decreasedWorkWork',
                label: '작업',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
            ]
          },
          {
            name: 'finalScore',
            field: 'finalScore',
            label: '최종점수',
            align: 'center',
            sortable: false,
            style: 'width:70px',
          },
        ]
      } else if (this.$route.meta.params.heaSurveyCategoryCd === 'MSC0000005') {
        cols = [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'investigationPlanName',
            field: 'investigationPlanName',
            label: '조사계획명',
            align: 'left',
            sortable: false,
            style: 'width:250px',
            type: 'link'
          },
          {
            name: 'year',
            field: 'year',
            label: 'LBLYEAR',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'worker',
            field: 'worker',
            label: '작업자',
            child: [
              {
                name: 'userName',
                field: 'userName',
                label: 'LBLNAME',
                align: 'center',
                sortable: false,
                style: 'width:80px',
              },
              {
                name: 'deptName',
                field: 'deptName',
                label: 'LBLDEPT',
                align: 'center',
                sortable: false,
                style: 'width:80px',
              },
              {
                name: 'heaMuscleSurveyStepName',
                field: 'heaMuscleSurveyStepName',
                label: '설문지 작성현황',
                align: 'center',
                sortable: false,
                style: 'width:80px',
              },
            ]
          },
          {
            name: 'processSopNames',
            field: 'processSopNames',
            label: '관련 공정/단위작업',
            align: 'left',
            sortable: false,
            style: 'width:400px',
          },
        ]
      }
      return cols;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      if (this.$route.meta.params.heaSurveyCategoryCd === 'MSC0000005') {
        this.listUrl = selectConfig.hea.muscleSystem.muscleSurvey.list.url
      } else {
        this.listUrl = selectConfig.hea.muscleSystem.muscleSurvey.status.url
      }
      // code setting
      this.$set(this.searchParam, 'heaSurveyCategoryCd', this.$route.meta.params.heaSurveyCategoryCd)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      if (this.$route.meta.params.heaSurveyCategoryCd === 'MSC0000005') {
        this.popupOptions.title = '근골격계질환 수시 증상조사표 현황';
        this.popupOptions.target = () => import(`${'./symptomSurveyStatusDetail.vue'}`);
        this.popupOptions.param = {
          heaInvestigationPlanId: row.heaInvestigationPlanId,
        };
        this.popupOptions.isFull = true;
      } else {
        this.popupOptions.title = '근골격계질환 정기 증상조사 설문지';
        this.popupOptions.target = () => import(`${'./symptomSurveyRoutineDetail.vue'}`);
        this.popupOptions.param = {
          heaMuscleWorkerSurveyId: row.heaMuscleWorkerSurveyId,
        };
        this.popupOptions.isFull = false;
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
